import Pagination from '@mui/material/Pagination';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { EN_CODE, FR_CODE } from '../common/Constants';
import Layout from '../components/Layout/Layout';
import Post from '../components/Post/Post';
import Sidebar from '../components/Sidebar/Sidebar';
import icon32 from '../pages/favicon.jpg';

const ListTemplate = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const posts = data.allMarkdownRemark.edges;
  const navArray = location?.pathname?.split('/');
  const pagingNumber = parseInt(navArray[navArray.length - 1], 10);

  const items = posts.map((post) => (
    <Post
      data={post}
      key={post.node.fields.slug}
      lang={post.node.frontmatter.lang}
    />
  ));

  const gotoPage = (evt, page) => {
    if (page === 1) {
      if (pageContext?.lang === 'en') {
        navigate('/');
      } else {
        navigate('/fr');
      }
    } else if (pageContext?.lang === 'en') {
      navigate(`/en/page/${page}`);
    } else {
      navigate(`/fr/page/${page}`);
    }
  };

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>{siteTitle}</title>
        <script defer data-domain="tderflinger.com" src="https://plausible.io/js/script.js"></script>
        <meta
          name="description"
          content="My name is Thomas Derflinger, I'm a freelance web programmer, and this is my blog. I write about a lot of things, but mostly web programming, DevOps, VR, and IoT."
        />
        <link rel="shortcut icon" type="image/jpeg" href={icon32} />
      </Helmet>
      <Sidebar
        data={data}
        lang={pageContext?.lang === 'en' ? EN_CODE : FR_CODE}
      />
      <div className="content">
        <div className="mt-10 mx-6 mb-4">{items}</div>
        <Pagination
          count={pageContext?.lang === 'en' ? 6 : 4}
          color="primary"
          className="mb-8"
          onChange={gotoPage}
          page={pagingNumber}
        />
      </div>
    </Layout>
  );
}

export default ListTemplate;

export const pageQuery = graphql`
  query ListTemplateQuery($skip: Int!, $limit: Int!, $lang: String!) {
    site {
      siteMetadata {
        title
        url
        author {
          name
          nameFr
          twitter
          github
          rss
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          layout: { eq: "post" }
          draft: { ne: true }
          lang: { eq: $lang }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            lang
            path
            pathEn
            license
            date
            description
            category
            attachments {
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
